<template>
  <div>
       <!-- 创建 -->
    <el-table
            ref="multipleTable"
                        :height="__tableHeight__"
            :data="tableData"
            stripe
            tooltip-effect="dark"
            style="width: 96%;text-align: center">
        <el-table-column align="center" type="selection" width="65 "> </el-table-column>
        <el-table-column label="类型"  min-width="65" align="center">
            <template slot-scope="scope">{{ scope.row.type }}</template>
        </el-table-column>
        <el-table-column label="创建时间"  min-width="65" align="center">
            <template slot-scope="scope">{{ scope.row.create_time }}</template>
        </el-table-column>
        <el-table-column label="状态"  min-width="55" align="center">
            <template slot-scope="scope">
                <span style="color:#f00;">{{scope.row.status}}</span>
            </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="scope">
                <el-button type="text" size="small"  @click="remove(scope.row.id)">
                    一键直达处理
                </el-button>
            </template>
        </el-table-column>
    </el-table>
    <!-- 页码 -->
    <div class="block" >
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="req.page"
                :page-size="req.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                class="flex-right">
        </el-pagination>
    </div>
    <div style="height:60px;"></div>
  </div>
</template>

<script>

export default {
    props:{
        printId:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            tableData:[],
            total:0,
            currentPage:1,
            req:{
                limit:30,
                page:1
            }
        }
    },
    methods:{
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        getList(){
            // this.req.id = this.printId;
            // shareList(this.req).then((res) => {
            //     this.tableData = res.data.data;
            //     this.total = res.data.total;
            // }).catch((err) => {
            //     this.$message('网络或程序错误，请稍候重试！', 'error');
            // });
            this.tableData = [
                {
                    type:'采购单',
                    status:'待处理',
                    create_time:'2021-11-10 20:30:24'
                },
                {
                    type:'销售单',
                    status:'待处理',
                    create_time:'2021-11-10 20:30:24'
                },
                {
                    type:'销售单',
                    status:'待处理',
                    create_time:'2021-11-10 20:30:24'
                },
                {
                    type:'销售单',
                    status:'待处理',
                    create_time:'2021-11-10 20:30:24'
                }
            ];
        },
        exit(){
            this.$emit('shareExit')
        },
        update(tplId,){
            
        }
    },
    created(){
        this.getList();
    }
}
</script>
<style scoped lang="less">
@import url('../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
</style>