import tools from "../../../extends/tools.js";

export default {
    shareListFlag: false,
    editableTabsValue: '1',
    //菜单位置、左右
    selectSide: localStorage.getItem('selectSide') ? localStorage.getItem('selectSide') : 'left',
    // 导航条
    selectSide2: localStorage.getItem('selectSide2') ? localStorage.getItem('selectSide2') : 'left2',
    selectSide3: localStorage.getItem('selectSide3') ? localStorage.getItem('selectSide3') : 'left3',

    leftSide: {
        left: '75px',
    },
    rightSide: {
        left: '0px',
    },
    editableTabs: [],
    tabIndex: 1,

    menuList: [],
    portrait: require('../../../assets/img/home/d_tp.png'),
    roleId: 0,
    tuiFlag: false,
    qrcode: require('../../../assets/img/home/qun_qrcode.jpg'),
    close: false,
    courseUrl: '',
    minWidth: document.body.clientWidth - 130,
    userInfo: {},
    configData: {

    },
    baseURL: tools.baseURL(),
}