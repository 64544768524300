<template>
    <div >
        <div class="pageTop" style="position:fixed;z-index:1;width:100%;" :class="selectSide2">
            <!-- banner -->
            <div class="banner" id="top_banner" >
                <div class="text-box" v-if="$platform !== 'alibaba'" style="line-height:40px;">
                    {{configData.banner}}
                </div>
                <Alibaba v-if="$platform==='alibaba'"></Alibaba>
            </div>
            <!-- 页面选项卡 -->
            <div class="pageTab" >
                <el-tabs v-model="editableTabsValue" @tab-click="tabFocus" type="card" closable @tab-remove="removeTab">
                <el-tab-pane 
                    v-for="(item, index) in editableTabs"
                    :key="index"
                    :label="item.title"
                    :name="item.name"
                >
                </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div class="qrDiv" :style="{width:close==false?'130px':'0',height:close==false?'200px':'0',left:selectSide=='right'?'10px':'90%'}">
            <div :style="{right:selectSide=='left'?'10px':'none',left:selectSide=='right'?'10px':'none'}" style="color:blue;width:20px;height:20px;background:#fff;position:absolute;top:-20px;cursor:pointer;text-align:center;line-height:20px;">
                <span v-if="close==false" @click="close=true" class="el-icon-circle-close" style="font-size:20px;"></span>
                <span v-if="close==true" @click="close=false" style="font-size:26px;color:#006eff;" class="el-icon-message-solid"></span>
            </div>
            <div style="overflow:hidden;">
                <img :src="baseURL+'/'+configData.qrcode_qun" alt="" style="width:130px;height:130px;">
                <p style="height:30px;line-height:30px;text-align:center;font-size:16px;color:blue;">{{configData.qun_word}}</p>
            </div>
        </div>
        <div class="nav"></div>
        <div class="nav" style="position:fixed;z-index:100;top:0px;" :class="selectSide">
            <el-row :gutter="1">
                <div class="cl-title" >
                    <div class="img_box" style="position:relative;">
                        <img :src="portrait" height="60" width="60" />
                        <div @click="tui" 
                            class="popo"
                            :style="{left:selectSide=='left'?'42px':'none',right:selectSide=='right'?'42px':'none'}" 
                            v-show="tuiFlag" >
                            <p>你有 7 条待办信息，点击查看</p>
                        </div>
                    </div>
                    <i class="el-icon-setting"></i>
                </div>
                <div class="vertical-menu">
                    <div :index="key" v-for="(item,key) in menuList" :key="key" >
                        <div class="each_menu" slot="title" @mouseover="showChild(key,menuList)"   :class="[item.isActive?'indexes':'']" :style="{background:item.isUIActive?'#006eff':''}">
                            <!-- <div style="height:25px;"></div> -->
                            <div  style="height: 30px;text-align: center" >
                                <div class="menu_icon iconfont " :class="item.icon"></div>
                            </div>
                            <div style="color: #fff;text-align: center" >
                                <span>{{item.name}}</span>
                            </div>
                        </div>
                        <!-- <div class="childrenGroup" v-if="false" >
                            <div class="groups" >{{item.name}}</div>
                            <div  :index="k" :key="k" @click="jump(i.path,k,item.children,$route.path)" :class="[i.isActive==1?'active':'',i.isHidden==1?'kong':'group']" >{{i.name}}</div>
                        </div> -->
                        <div class="childDiv" :class="selectSide3"  :style="{top:((key+1)*60-(2*key))+'px'}"  v-show="item.isActive" >
                            <!-- 二级菜单 -->
                            <div class="secondMenu" v-for="(i,k) in item.children"
                             :key="k" @click.self="showPage(i,item)" 
                             @mouseover="showChild(k,item.children)" 
                             :style="{height:i.isActive?(i.children.length+1)*35+'px':'35px'}"
                             :class="{isActive:i.isActive}">
                                {{i.name}}
                                <i class="icon iconfont icon-you- secondArrow" v-show="!!i.children && i.children.length>0"></i>
                                <!-- 三级菜单 -->
                                <div class="thirdMenu" v-for="(ii,kk) in i.children" :key="kk" v-show="i.isActive" @click.self="showPage(ii,item)">
                                    {{ii.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="side">
                    <slot name="item"></slot>
                </div>
            </el-row>

            <div @click="changeSideHandle" title="切换导航栏位置" class="icon iconfont icon-left-right-switch changeSide">
                
            </div>
            <!-- 刷新 -->
            <i class="changeSide icon iconfont icon-logout " style="bottom:104px;font-size:27px;" @click="tui()" title="退出登录"></i>
            <i class="changeSide icon iconfont icon-shuaxin " style="bottom:58px;font-size:28px;" @click="reflesh()" title="刷新本页"></i>
        </div>
        <!-- websocket -->
        <socket></socket>
        <el-dialog
            :modal-append-to-body="false"
            title="待办列表"
            :visible.sync="shareListFlag"
            v-if="shareListFlag"
            width="600px"
            height="400px"
            center>
            <shareList :id="currentId" ></shareList>
        </el-dialog>
    </div>
</template>

<script>
    import methods from "./NavBarCollect/method.js";
    import data from "./NavBarCollect/data.js";
    import shareList from './listDialog';
    import Alikey from './alikey';
    import Socket from './Socket';
    import Alibaba from './alibaba.vue';
    export default {
        name: "Navbar",
        inject: ['reload'],  //添加此行
        data(){
            return data;
        },
        components:{
            Alikey,Socket,Alibaba,shareList
        },
        async mounted(){
            document.body.onscroll = function(){
                if(document.querySelector('html').scrollTop>40){
                    document.querySelector('#top_banner').style.display = 'none';
                }else{
                    document.querySelector('#top_banner').style.display = 'block';
                }
            }
            this.initData();
        },
        methods:methods,
    }
</script>

<style scoped lang="less">
    @import url("./less/navbar");
    .popo{
        width:240px;height:30px;position:absolute;background:#006eff;top:5px;right:-80px;z-index:99;border-radius:10px;
        p{
            text-align:center;line-height:30px;height:30px;color:#fff;cursor:pointer;
        }
    }
</style>
