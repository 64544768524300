<template>

</template>

<script>
    export default {
        name: "top",
        data(){
            return{
                portrait:require('../../assets/img/home/d_tp.png'),
                username:'未设置',
                storeName:'东坡金拓分店',
                showMe:false,
                roleId:1,
                courseUrl:''
            }
        },
        mounted(){
            let info = JSON.parse(localStorage.getItem('userinfo'));
            if(info.username){
                this.username = info.username;
            }
            if(info.portrait){
                this.portrait = require(info.portrait);
            }
            if(info.username){
                this.roleId = info.register_role_id;
            }
        },
        methods:{
            tui(){
                localStorage.setItem('loginStatus',null);
                this.$router.push({
                    path:'/home'
                })
            },
            myMsg(){
                // alert('跳转消息页,开发中');
            },
            //修改密码
            changepassword(){
                this.$router.push({
                    path:'/ChangePassword'
                })
            }
        }
    }
</script>
<style scoped lang="less">
    .header-box{
        align-items: center;
        width: 100%;
        background-color: #fff;
        .text-box{
            font-size: 16px;
        }
    }
</style>