import tools from "../../../extends/tools.js";
import apis from "../../../views/common/js/api2";

export default {
    // 刷新
    reflesh() {
        localStorage.setItem('selectSide', 'left');
        localStorage.setItem('selectSide2', 'left2');
        localStorage.setItem('selectSide3', 'left3');
        this.selectSide = 'left';
        this.selectSide2 = 'left2';
        this.selectSide3 = 'left3';
        this.reload();
    },

    changeSideHandle() {
        if (this.selectSide == 'left') {
            this.selectSide = 'right';
        } else {
            this.selectSide = 'left';
        }

        if (this.selectSide2 == 'left2') {
            this.selectSide2 = 'right2';
        } else {
            this.selectSide2 = 'left2';
        }

        if (this.selectSide3 == 'left3') {
            this.selectSide3 = 'right3';
        } else {
            this.selectSide3 = 'left3';
        }

        localStorage.setItem('selectSide', this.selectSide);
        localStorage.setItem('selectSide2', this.selectSide2);
        localStorage.setItem('selectSide3', this.selectSide3);
        location.reload();
    },
    jump(path, key, children, rpath) {
        children.forEach((item, index) => {
            item.isActive = false;
            if (index == key) {
                item.isActive = true;
            }
        });
        if (rpath != path) {
            this.$router.push({
                path: path
            })
        }
    },
    hideChild(item) {
        if (item) {
            item.forEach((item, index) => {
                item.isActive = false;
            });
            return;
        }
        this.menuList.forEach((item, index) => {
            item.isActive = false;
        });
    },
    showChild(key, arr) {
        arr.forEach((item, index) => {
            item.isActive = false;
            if (index == key) {
                item.isActive = true;
            }
        });
    },
    tui() {
        localStorage.setItem('uid', '');
        localStorage.setItem('isReg', false);
        localStorage.setItem('menu', null);
        localStorage.setItem('userinfo', null);
        localStorage.setItem('authorization-bearer', null);
        // 供前端路由访问
        localStorage.setItem('loginStatus', 0);
        this.tuiFlag = false;
        location.reload();
        this.$router.push({
            path: '/home'
        })
    },
    //显示
    showPage(item, parent) {
        if (item.path != this.$router.currentRoute.fullPath) {
            //显示路由
            this.$router.push({
                path: item.path
            });
        }
        this.menuList.forEach((item) => {
            item.isUIActive = false;
        });
        item.isActive = false;
        parent.isActive = false;
        parent.isUIActive = true;
        //处理重复点击
        let flag = true;
        this.editableTabs.forEach((it, index) => {
            if (it.title == item.name) {
                this.editableTabsValue = it.name;
                flag = false;
            }
        });
        if (!flag) {
            return;
        }
        this.addTab(item.name, item.path);
    },
    addTab(targetName, path) {
        let newTabName = ++this.tabIndex + '';
        this.editableTabs.push({
            title: targetName,
            name: newTabName,
            content: '',
            path: path
        });
        // console.log({
        //     title: targetName,
        //     name: newTabName,
        //     content: '',
        //     path: path
        // });
        this.editableTabsValue = newTabName;
    },
    //点击
    tabFocus(obj) {
        let path = this.editableTabs[obj.index].path;
        if (path != this.$router.currentRoute.fullPath) {
            //显示路由
            this.$router.push({
                path: path
            });
        }
    },
    removeTab(targetName) {
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        if (activeName === targetName) {
            tabs.forEach((tab, index) => {
                if (tab.name === targetName) {
                    let nextTab = tabs[index + 1] || tabs[index - 1];
                    if (nextTab) {
                        activeName = nextTab.name;
                        //显示路由
                        this.$router.push({
                            path: nextTab.path
                        });
                    }
                }
            });
        }
        this.editableTabsValue = activeName;
        this.editableTabs.splice(this.editableTabs.findIndex(item => item.name == targetName), 1);
    },
    initData() {
        //全局隐藏
        document.querySelector('body').onclick = () => {
            this.hideChild();
        };
        //初始化配置
        apis.getConfig({

        }).then(res => {
            let data = res.data;
            data.forEach(element => {
                this.$set(this.configData, element.name, element.value);
            });
        }).catch(err => {});
        //如果没有标签卡，获取当前地址，根据地址匹配一个标签卡
        if (this.editableTabs.length === 0) {
            let r = {
                content: "",
                name: "1",
                path: this.$route.path,
                title: document.title
            }
            this.editableTabs.push(r);
        }

        // 数据初始化
        // console.log(this.roleId)
        if (!this.portrait || !this.roleId) {
            let info = JSON.parse(localStorage.getItem('userinfo'));
            this.roleId = info.register_role_id;
            if (info.portrait) {
                this.portrait = info.portrait;
            } else {
                this.portrait = require('../../../assets/img/home/d_tp.png');
            }
        }

        // let menuListStatic = JSON.parse(localStorage.getItem('menuListStatic'));
        // if (!!menuListStatic) {
        //     this.menuList = tmpArr;
        //     return;
        // }
        if (this.menuList.length == 0) {
            // 初始化菜单数据，这个数据一般是登录时初始化的
            let menu = JSON.parse(localStorage.getItem('menu'));
            let tmpArr = [];
            menu.forEach((item, index) => {
                let parent = {
                    name: item.name,
                    icon: item.icon,
                    isActive: false,
                    isUIActive: false,
                    path: item.component,
                    icon: item.icon,
                    children: []
                };
                if (item.name === '模版管理' && this.roleId === 1) {
                    parent.isUIActive = true;
                }
                // console.log(this.roleId);
                // console.log(item);
                if (item.name === '打印发货' && this.roleId === 2) {
                    parent.isUIActive = true;
                }

                if (item.child) {
                    item.child.forEach((i, k) => {
                        let child1 = {
                            name: i.name,
                            icon: i.icon,
                            isActive: false,
                            path: i.component,
                            children: []
                        }
                        parent.children.push(child1);
                        if (!!i.child) {
                            //组装三级菜单
                            i.child.forEach((ii, kk) => {
                                let bb = kk == 0;
                                let child2 = {
                                    name: ii.name,
                                    icon: ii.icon,
                                    isActive: false,
                                    path: ii.component
                                }
                                child1.children.push(child2);
                            })
                        }
                    });
                }

                tmpArr.push(parent);
            })
            this.menuList = tmpArr;
        }

    }
}