<template>
  
</template>

<script>
export default {
    mounted(){
        this.init();
    },
    data(){
        return {
            flag:true
        }
    },
    methods:{
        init(){

            const URL = 'ws://39.98.189.174:30003';
            console.log(URL);
            var ws = new WebSocket(URL);
                // let info = JSON.parse(localStorage.getItem('userinfo'));
                // console.log(info);
            ws.onopen = (evt)=>{
                let info = JSON.parse(localStorage.getItem('userinfo'));
                // console.log(info);
                let login = {type:"login","user_id":info.id,"client_name":info.account,"room_id":'chat'};
                // console.log(login);
                ws.send(JSON.stringify(login));
            };

            let timer = setInterval(()=>{
                ws.send(JSON.stringify({type:'working'}));
            },3000);  
            ws.onmessage = (evt)=>{
                if(!evt.data){
                    return;
                }
                // console.log(evt.data);
                let data = JSON.parse(evt.data);
                
                if(data.type === 'notice'){
                    this.$notify({
                        title: '重要提醒',
                        type:'warning',
                        position:'bottom-right',
                        message: data.content,
                        duration: 5000
                    });
                }

                // 自动发货
                if(data.type === 'automatic_print_send'){
                    //有正在处理的订单队列就不处理
                    let CURRENT_ORDER_LIST = [];
                    if(this.flag === true){
                        localStorage.setItem('CURRENT_ORDER_LIST','');
                    }
                    this.flag = false;
                    let str = localStorage.getItem('CURRENT_ORDER_LIST')?localStorage.getItem('CURRENT_ORDER_LIST'):'[]';

                    CURRENT_ORDER_LIST = JSON.parse(str);
                    if(CURRENT_ORDER_LIST.length>0){
                        return;
                    }
                    // 
                    let orderList = data.content.data;
                    orderList.forEach(element => {
                        CURRENT_ORDER_LIST.push(element);
                    });

                    localStorage.setItem('CURRENT_ORDER_LIST',JSON.stringify(CURRENT_ORDER_LIST));
                }

            };
            ws.onclose = (evt)=>{ 
                console.log("Connection closed.");
                clearInterval(timer);
            };     
        }
    }
}
</script>

<style>

</style>